html {
  font-size: 14px;
  body {
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.6;
    font-family: $mainFont;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    min-height: 100vh;
    #root {
      min-height: 100vh;
      display: flex;
      flex-direction: column; }
    .app {
      padding: .5rem 1rem;
      box-sizing: border-box;
      flex: 1;
      display: flex; }

    .page {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 210mm;
      max-width: 210mm;
      margin: 0 auto;
      font-size: 14px; }

    .main_content {
      flex: 1;
      margin-top: 1.5rem; } } }


@media print {
  html {
    font-size: 12px; }
  .no_print {
    display: none; } }
