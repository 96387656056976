.Toastify {
  .custom-toast {
    font-family: 'Noto Sans Japanese'; }
  .Toastify__toast--success {
    background-color: $success; }
  .Toastify__toast--error {
    background-color: $red; }
  .Toastify__toast--warning {
    background-color: $warning; }
  .Toastify__toast--info {
    background-color: $blue; } }
