@import '../../styles/1-tools/_vars.sass';
$dotColor: $white;
$rad: 6px;
.dot-loader {
  width: ($rad * 3) + (($rad / 2) * 3);
  text-align: center;
  > div {
    width: $rad;
    height: $rad;
    margin: 0 $rad/4;
    background-color: $dotColor;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .bounce1 {
    animation-delay: -0.32s; }
  .bounce2 {
    animation-delay: -0.16s; } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }

  40% {
    transform: scale(1); } }

.page_loader_container {
  flex: 1; }
