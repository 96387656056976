@import '../../../styles/1-tools/_vars.sass';
.select_container {
  position: relative;
  margin: 1rem 0;
  align-items: flex-end;
  &.required {
    .select_label_container {
      .select_label {
        &:before {
          content: '*';
          position: absolute;
          top: 0;
          left: 0;
          color: $red;
          font-size: 1rem;
          line-height: 1rem; } } } }
  .select_label_container {
    display: flex;
    align-items: center;
    .select_label {
      // position: relative
      // margin-right: .5rem
      // padding: 0 .5rem
      // box-sizing: border-box

      color: inherit;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 .5rem;
      pointer-events: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-text-orientation: ellipsis;
      text-orientation: ellipsis;
      overflow: hidden;
      transform-origin: 0 0;
      transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transform: scale(0.75); } }
  .select_inner_container {
    position: relative;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -2.5px;
      z-index: 0; }
    select {
      position: relative;
      appearance: none;
      border: none;
      outline: none;
      background-color: transparent;
      padding: .45rem 1.1rem .45rem .5rem;
      cursor: pointer;
      display: inline-flex;
      box-sizing: border-box;
      z-index: 1;
      border-bottom: 1px solid $light;
      border-radius: 0;
      &:active, &:focus {
        border-bottom-color: $red; }
      option {} } }
  span {
    margin-left: .25rem; } }
