@import '../../styles/1-tools/_vars.sass';
.create_document {
  flex: 1;
  .print_button {
    max-width: 960px;
    margin: 0 auto;
    .btn {
      margin-left: auto; } }
  h4.id {
    text-align: right;
    max-width: 960px;
    margin: 1rem auto;
    font-size: 1rem;
    color: $dark; }
  .form {
    max-width: 960px;
    margin: 1rem auto;
    .client {
      .select_container {
        margin-left: 1.5rem; } }
    .taxRate {
      width: 75px;
      text-align: right; } }
  .buttons_container {
    max-width: 960px;
    margin: 4rem auto;
    .btn:not(:last-child) {
      margin-right: 2rem; } }
  .items {
    margin: 1.5rem 0;
    .items_title {
      font-size: 1.1rem; }
    .item {
      position: relative;
      &.dragging {
        box-shadow: $lightShadow;
        background-color: lighten(#ccbd36, 40%);
        * {
          background: transparent; } }
      .handle {
        width: 2rem;
        height: auto;
        position: absolute;
        left: -2.75rem;
        top: 50%;
        transform: translateY(-50%);
        svg rect {
          fill: $warning; } }

      .textinput_container:not(:first-of-type) {
        margin-left: 1rem;
        width: 30%;
        input {
          text-align: right; } }
      .delete_btn {
        $width: 40px;
        position: absolute;
        top: 50%;
        right: -$width * 1.5;
        transform: translateY(-50%);
        width: $width;
        height: $width;
        border-radius: 50%;
        appearance: none;
        outline: none;
        border: none;
        background-color: $red;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          width: 75%;
          height: auto;
          path {
            fill: $white; } } } }
    .add_container {
      .add_button {
        transform: scale(.85);
        svg circle {
          fill: $blue; } } } } }

