@import '../../styles/1-tools/_vars.sass';
aside.payment_methods {
  .payment_title_english {
    font-size: 1.2em;
    text-transform: uppercase; }
  .payment_title_japanese {
    font-size: 1.1em; }
  .payment_group {
    margin-top: 1rem;
    h5 {
      font-size: 1em; }
    .banks {
      list-style: none;
      padding: 0;
      margin: 1rem 0 0 0;
      white-space: nowrap;
      font-size: .85em;
      .bank {
        margin-bottom: 1rem;
        .value {
          font-weight: bold; } } } } }
