footer {
  margin-top: 1rem;
  .img_container {
    width: 70px;
    img {
      display: block;
      width: 100%;
      height: auto; } }
  .contact {
    justify-content: space-around;
    padding-top: .75rem;
    box-sizing: border-box;
    font-size: .85rem;
    .item {
      align-items: center;
      &:first-child {
        white-space: pre-wrap; }
      &:nth-child(2) {
        margin: 0 1rem; }
      &:not(:first-child) {
        white-space: nowrap; }
      .item_image {
        width: 24px;
        margin-right: .5rem;
        img {
          display: block;
          width: 100%;
          height: auto; } }
      .item_content {
        letter-spacing: 2.5px; } } } }
