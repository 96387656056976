@import '../../../styles/1-tools/_vars.sass';
$width: 20%;
$skewBy: 40deg;
$translateBy: 2.5rem;
$xPadding: 2rem;
.table_header {
  overflow: hidden;
  .table_header_item {
    color: $white;
    transform: skew(-$skewBy);
    &:nth-child(even) {
      background-color: $red; }
    &:nth-child(odd) {
      background-color: $blue; }
    &:not(:first-child) {
      margin-left: .75rem; }
    &:first-child {
      transform: skew(-$skewBy) translateX(-$translateBy);
      margin-right: -$translateBy;
      padding: .15rem calc(#{$xPadding} - 1.75rem) .15rem calc(#{$xPadding} - .5rem); }
    &:last-child {
      transform: skew(-$skewBy) translateX($translateBy);
      margin-left: -1.75rem;
      padding: .15rem calc(#{$xPadding} - 1.5rem) .15rem 0; }
    &.item {
      width: 40%; }
    &.rate {
      width: $width; }
    &.quantity {
      width: $width; }
    &.price {
      width: $width; }

    .table_header_item_inner {
      transform: skew($skewBy);
      padding: .25rem $xPadding;
      box-sizing: border-box; }
    .english_title {
      text-align: left;
      font-size: 2em;
      line-height: 2.5rem; }
    .japanese_title {
      font-size: .85em;
      text-align: right; } } }
