@import '../../../styles/1-tools/_vars.sass';
$skewBy: 40deg;
$translateBy: 2.5rem;
$xPadding: 3rem;

.table_total {
  max-width: 400px;
  margin-top: 2rem;
  margin-right: 0;
  margin-left: auto;
  h4 {
    flex: 1;
    font-size: 1.25em;
    display: flex;
    align-items: center;
    span {
      margin-left: .5rem;
      font-size: .9em; } }
  span.number {
    font-size: 1.25em; }
  .subtotal, .tax, .subtotal_amount, .tax_amount {
    align-items: baseline; }
  .subtotal {
    margin-bottom: .5rem;
    padding-right: 1.75rem; }
  .tax {
    margin-bottom: 1rem;
    padding-right: 1.75rem; }
  .total_row {
    overflow: hidden;
    line-height: 1.4rem;
    .table_header_item {
      color: $white;
      transform: skew(-$skewBy);
      &:nth-child(even) {
        background-color: $blue; }
      &:nth-child(odd) {
        background-color: $red; }
      &:not(:first-child) {
        margin-left: .75rem; }
      &.total_title {
        width: 200px;
        transform: skew(-$skewBy) translateX(-$translateBy);
        margin-right: -$translateBy;
        padding: 0 calc(#{$xPadding} - 4.5rem) 0 calc(#{$xPadding} - 2.5rem); }
      &.total_number {
        flex: 1;
        transform: skew(-$skewBy) translateX($translateBy);
        margin-left: -1.75rem;
        padding: .25rem calc(#{$xPadding} - 3.5rem);
        display: flex;
        justify-content: center;
        align-items: center;
        .table_header_item_inner {
          padding-right: 0rem; }
        .total_number_content {
          font-size: 1.5em; } }
      .table_header_item_inner {
        transform: skew($skewBy);
        padding: .25rem $xPadding;
        box-sizing: border-box; }
      .english_title {
        text-align: left;
        font-size: 1.5em; }
      .japanese_title {
        text-align: right;
        font-size: 1em; } } } }
