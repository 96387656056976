// Colors
$white: rgb(255,255,255);
$black: #1e1e1e;
$transparentWhite: rgba(255,255,255,.3);

$dark: #47453F;
$red: #A32500;
$blue: #313B47;
$kaki: #BAB293;
$light: #707070;
$success: #08a300;
$warning: #ccbd36;

// Shadows
$lightShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$darkShadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);

// Dimensions
$rad: 5px;

// Typography
$mainFont: 'Noto Sans Japanese', sans-serif;
