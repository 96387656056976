@import '../../../styles/1-tools/_vars.sass';
$width: 20%;
$skewBy: 40deg;
$translateBy: 2.5rem;
$xPadding: 2rem;
.table_row {
  border-bottom: 1px solid transparentize($dark, .8);
  padding: .75rem 0;
  .column {
    padding: .5rem $xPadding;
    font-size: 1.2em; }
  .small_column {
    width: $width;
    text-align: right; }
  .item {
    width: 50%; }

  .rate {}
  .quantity {}
  .price {} }
