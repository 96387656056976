@import '../../../styles/1-tools/_vars.sass';
.textarea_container {
  position: relative;
  margin: 1rem 0;
  .textarea {
    border: none;
    outline: none;
    color: inherit;
    border-top: .84375em solid transparent;
    padding: .75rem 0;
    width: 100%;
    line-height: 1.25rem;
    border-bottom: 1px solid;
    border-bottom-color: inherit;
    resize: none;
    min-height: 1.25rem;
    height: 4rem;
    max-height: 300px;
    box-sizing: border-box;
    position: relative;
    &:focus {
      border-bottom-color: $red; }
    &:required ~ .label_container label {
      &:after {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
        color: $red;
        font-size: 1rem;
        line-height: 1rem; } }
    &.empty:not(:focus) {
      & + .label_container {
        label {
          transform: scale(1) translateY(1.2em); } } } }

  .label_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // overflow: hidden
    pointer-events: none;
    box-sizing: content-box;
    color: $blue;

    label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      transform-origin: 0 0;
      transition: transform .4s cubic-bezier(.25,.8,.25,1),color .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1);
      transform: scale(.75);
      box-sizing: border-box;
      padding: 0 .5rem; } } }
