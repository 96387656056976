@import '../../styles/1-tools/_vars.sass';
$actionBtnWidth: 245px;
.list_page {
  flex: 1;
  .dot-loader {
    margin: 5rem auto;
    transform: scale(2);
    & > div {
      background-color: $blue; } }
  .list_items {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    .list_items_headers {
      display: flex;
      font-size: 1.25rem;
      font-family: 'Honoka';
      padding-bottom: 1rem;
      border-bottom: 1px solid $light;
      .list_items_header {
        text-align: center;
        &:first-child {
          width: calc((100% - #{$actionBtnWidth}) * 0.40); }
        &:nth-child(2) {
          width: calc((100% - #{$actionBtnWidth}) * 0.30); }
        &:nth-child(n+3) {
          width: calc((100% - #{$actionBtnWidth}) * 0.15); }
        &:nth-child(5) {
          width: 245px; } } }
    .list_item {
      display: flex;
      padding: 1rem 0;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid $light; }
      &:hover {
        color: $red;
        background-color: rgba(163,37,0,.05); }
      .list_item_info {
        padding: 1rem;
        &:first-child {
          width: calc((100% - #{$actionBtnWidth}) * 0.40); }
        &:nth-child(2) {
          width: calc((100% - #{$actionBtnWidth}) * 0.30); }
        &:nth-child(n+3) {
          width: calc((100% - #{$actionBtnWidth}) * 0.15);
          text-align: right; }
        &:nth-child(5) {
          width: 245px; }
        &.actions {
          display: flex;
          button {
            margin: 0 .25rem;
            font-size: .85rem;
            width: auto;
            padding: .5rem 1rem;
            box-shadow: none; } } } } } }
