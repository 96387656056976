@import '../../../styles/1-tools/_vars.sass';
section.remarks {
  margin-top: 2rem;
  h4 {
    font-size: 1em;
    margin-bottom: .5rem; }
  .remarks_content {
    border-top: 1px solid transparentize($dark, .8);
    border-bottom: 1px solid transparentize($dark, .8);
    min-height: 100px;
    white-space: pre-wrap; } }

