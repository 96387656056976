@import '../../../styles/1-tools/_vars.sass';
.textinput_container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  input {
    border: none;
    outline: none;
    color: inherit;
    padding: .5rem 0;
    border-top: .84375em solid transparent;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    line-height: 1rem;
    border-bottom: 1px solid;
    border-bottom-color: inherit;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    &:focus {
      border-bottom-color: $red;
      & ~ .label_container label {
        color: $red; } }
    &.empty:not(:focus) {
      & + .label_container {
        label {
          transform: scale(1) translateY(1.2em); } } }
    &:required ~ .label_container label {
      &:after {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
        color: $red;
        font-size: 1rem;
        line-height: 1rem; } } }

  .suffix {
    color: $blue; }

  .label_container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // overflow: hidden
    pointer-events: none;
    box-sizing: content-box;
    color: $blue;
    label {
      color: inherit;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 .5rem;
      pointer-events: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-orientation: ellipsis;
      overflow: hidden;
      transform-origin: 0 0;
      transition: transform .4s cubic-bezier(.25,.8,.25,1),color .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1);
      transform: scale(.75); } } }
