@import '../../styles/1-tools/_vars.sass';
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  h1 {
    font-size: 1.5rem;
    color: $blue; }
  nav {
    display: flex;
    align-items: center;
    .navLink {
      text-decoration: none;
      color: $blue;
      font-size: 1.2rem;
      border-bottom: 1px solid transparent;
      &:not(:last-child) {
        margin-right: 1.5rem; }
      &.active {
        color: $red; }
      &:hover {
        border-color: $red; } }
    .logoutBtn {
      appearance: none;
      border: 1px solid $red;
      outline: none;
      border-radius: $rad;
      padding: .25rem .75rem;
      font-size: .85rem;
      cursor: pointer; } } }
