@import '../../styles/1-tools/_vars.sass';
.add_button {
  height: 50px;
  width: 50px;
  margin: 1rem 0 1rem auto;
  cursor: pointer;
  svg {
    height: 100%;
    width: 100%;
    circle {
      fill: $red; }
    line {
      fill: none;
      stroke: $white;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10; } } }
