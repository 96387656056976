@import '../../../styles/1-tools/_vars.sass';
.document_header {
  .top {
    align-items: center;
    .left {
      text-align: right;
      h1 {
        font-size: 3.5rem;
        margin-bottom: .25rem; }
      h2 {
        font-size: 1.2rem; } }
    .right {
      span {
        &:first-child {
          margin-right: 1rem; } } } }

  .client {
    margin-top: 1rem;
    font-size: 1.25em;
    color: $red; }
  .subject_block {
    margin-top: 2rem;
    span {
      margin-left: 1rem;
      font-size: 1.1em; }
    .amount span {
      color: $red;
      &:first-child {
        font-size: 1.2rem;
        margin-right: 2px; }
      &:last-child {
        margin-left: 0; } } }

  .subject {
    margin-bottom: .5rem; }
  .amount {}
  .expiration {} }
