@import '../../../styles/1-tools/_vars.sass';
.datepicker_container {
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0;
  position: relative;
  .label {
    align-self: flex-start;
    transform: scale(.75);
    transform-origin: top left;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0 .5rem;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    // overflow: hidden
    &.required {
      &:after {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
        color: $red;
        font-size: 1rem; } } }
  .datepicker_inner_container {
    border-top: .84375em solid transparent;
    padding: .5rem 0;
    position: relative;
    .picker_value {
      margin-left: .5rem;
      color: $blue;
      cursor: default; } } }


.react-datepicker-popper {
  z-index: 100; }
.datepicker_calendar {
  border: none;
  border-radius: 5px;
  box-shadow: $lightShadow;
  overflow: hidden;
  .react-datepicker__navigation--previous {
    border-right-color: $light; }
  .react-datepicker__navigation--next {
    border-left-color: $light; }
  .react-datepicker__year-read-view--down-arrow {
    border-top-color: $light; }
  .react-datepicker__header {
    background-color: $blue;
    color: $white;
    .react-datepicker__current-month {
      color: inherit; }
    .react-datepicker__day-name {
      color: inherit; } }
  .react-datepicker__month {
    color: $blue;
    .datepicker_day {
      color: inherit;
      &:hover {
        background-color: $light; }
      &.react-datepicker__day--selected {
        background-color: $blue;
        color: $white; }
      &.react-datepicker__day--today {
        // border: 1px solid $ternary
 }        // border-radius: $rad
      &.react-datepicker__day--disabled {
        color: lighten($dark, 25); }
      &.react-datepicker__day--in-range {
        background-color: lighten($red, 25); }
      &.react-datepicker__day--in-selecting-range {
        background-color: lighten($red, 40); } } }
  .react-datepicker__year-read-view--down-arrow {
    // margin-top: -2px
    top: 6px;
    border-width: .4rem; }
  .react-datepicker__year-read-view {}
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    background-color: $light;
    border: 1px solid $dark;
    border-radius: 5px;
    height: 150px!important;
    overflow: scroll!important;
    .react-datepicker__year-option,
    .react-datepicker__month-option {
      color: $blue; }
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $dark;
      width: 0; }
    .react-datepicker__navigation--years-previous {
      width: 0;
      border-top-color: $dark; } }
  .react-datepicker__today-button {
    background-color: $blue;
    color: $white; } }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .react-datepicker__year-read-view, .react-datepicker__month-read-view {
    position: relative!important;
    padding-right: 20px; }
  .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    position: absolute!important;
    right: 0; } }
