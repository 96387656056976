h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal;
  line-height: 1.2; }

.big, h1 {
  font-size: 2.441rem; } // 39.06px
.larger, h2 {
  font-size: 1.953rem; } // 31.25px
.large, h3 {
  font-size: 1.563rem; } // 25px
.medium, h4 {
  font-size: 1.25rem; } // 20px
.regular {
  font-size: 1rem; } // 16px
small, .small {
  font-size: 0.8rem; } // 12.8px
.smallest {
  font-size: 0.64rem; } // 10.24px

.bold {
  font-weight: bold; }

.text-center {
  text-align: center; }

.accent_font {
  font-family: 'Miama'; }
.accent_font_jp {
  font-family: 'Honoka'; }
.accent_color {
  color: $red; }
