@import '../../styles/1-tools/_vars.sass';
main.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  button {
    appearance: none;
    border: none;
    outline: none;
    background: rgb(0,164,149);
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 5px;
    cursor: pointer; } }
