.flex {
  display: flex; }
.column {
  display: flex;
  flex-direction: column; }
.justify-center {
  justify-content: center; }
.justify-between {
  justify-content: space-between; }
.justify-around {
  justify-content: space-around; }
.justify-start {
  justify-content: flex-start; }
.justify-end {
  justify-content: flex-end; }
.align-center {
  align-items: center; }
.align-end {
  align-items: flex-end; }
.text-center {
  text-align: center; }
.text-right {
  text-align: right; }

.main_container {
  height: 100%;
  flex: 1;
  width: 100%;
  min-height: 80vh; }

.container {
  max-width: 1200px;
  margin: auto; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%; }

.hidden {
  display: none; }
.half {
  max-width: 50%; }

.no_wrap {
  white-space: nowrap; }
