@import '../../styles/1-tools/_vars.sass';
.btn {
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  padding: .8rem 0;
  width: 155px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: $blue;
  box-shadow: $lightShadow;
  border: 1px solid $blue;
  transition: all 100ms ease-in-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  &:disabled {
    cursor: not-allowed;
    background-color: $light!important;
    color: $white!important;
    border: none;
    .dot-loader {
      margin-left: .5rem; }
    &:hover {
      background-color: $light!important;
      color: $white!important; } }
  &.primary {
    background-color: $blue;
    color: $white;
    &:hover {
      background-color: $white;
      color: $blue; } }
  &.reverse_primary {
    background-color: $white;
    color: $blue;
    box-shadow: none;
    &:hover {
      background-color: $blue;
      color: $white; } }

  &.accent {
    background-color: $white;
    border-color: $red;
    color: $red;
    box-shadow: none;
    // &:hover
    //   background-color: $red
    //   border-color: $red
    //   color: $white
    .icon_container {
      svg {
        circle, path {
          fill: $red; } } } }
  &.danger {
    background-color: $red;
    border-color: $red;
    color: $white;
    box-shadow: none; }

  &.datepicker {
    padding: .25rem;
    width: auto;
    border-color: $blue;
    box-shadow: none;
    &:hover {
      background-color: $blue;
      svg path:not(.no_fill) {
        fill: $white; } }
    svg {
      height: 18px;
      width: 18px;
      path.no_fill {
        fill: none; }
      path:not(.no_fill) {
        fill: $blue; } } }

  &.small {
    width: auto;
    padding: .3rem .7rem; }


  .button_loader {
    margin-left: .5rem; }
  &:hover {
    .button_loader {
      .dot-loader > div {
        background-color: $blue; } } }

  .icon_container {
    $size: 20px;
    width: $size;
    height: $size;
    margin-right: 1rem;
    svg {
      width: 100%;
      height: auto;
      circle, path {
        fill: $blue; } } } }
