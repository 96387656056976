@import '../../../styles/1-tools/_vars.sass';
.checkbox_container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  input {
    display: none;
    &:required ~ .label_container label {
      &:after {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
        color: $red;
        font-size: 1rem;
        line-height: 1rem; } }
    &:checked {
      & ~ .label_container .checkbox {
        border: 1px solid $red;
        span {
          display: block; } } } }
  .label_container {
    color: $blue;
    position: relative;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: inherit;
      box-sizing: border-box;
      span.label {
        padding: 0 .5rem;
        margin-right: .5rem; } }
    .checkbox {
      $size: 1rem;
      box-sizing: border-box;
      width: $size;
      height: $size;
      border-radius: $rad;
      padding: 8px;
      border: 1px solid $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: none;
        color: $red; } } } }
